import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { convertToDate, formatDate } from '../../../utils/dates'

import { useGetMyDueProspectsQuery } from '../../prospects/slice'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { ROUTES } from '../../../constants/routes'

const Overdue = () => {
  const { data: prospects, isLoading: isProspectsLoading } = useGetMyDueProspectsQuery()
  const [convertedProspects, setConvertedProspects] = useState([])
  const [overdueProspects, setOverdueProspects] = useState([])
  const [todaysProspects, setTodaysProspects] = useState([])
  const [urgentProspects, setUrgentProspects] = useState([])

  const navigate = useNavigate()

  const handleNavigate = (campaignId, prospectId) => {
    navigate(`${ROUTES.CAMPAIGNS}/${campaignId}/prospects/${prospectId}`)
  }

  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0)
  const todayEnd = new Date()
  todayEnd.setHours(23, 59, 59, 999)

  useEffect(() => {
    if (prospects) {
      const filteredProspects = prospects
        .filter((prospect) => prospect.campaign?.disposition?.['Is Hidden'] !== 'TRUE')
        .filter((prospect) => prospect.campaign?.disposition?.['Is Meeting'] !== 'TRUE')
      setConvertedProspects(
        filteredProspects.map((prospect) => ({
          ...prospect,
          dueDate: convertToDate(prospect.campaign?.['Next Action Date']),
        }))
      )
    }
  }, [prospects])

  useEffect(() => {
    if (convertedProspects.length > 0) {
      setOverdueProspects(
        convertedProspects
          .filter((prospect) => prospect?.dueDate && prospect?.dueDate < todayStart)
          .sort((a, b) => a.dueDate - b.dueDate)
      )
      setTodaysProspects(
        convertedProspects
          .filter((prospect) => prospect?.dueDate && prospect?.dueDate >= todayStart && prospect?.dueDate <= todayEnd)
          .sort((a, b) => a.dueDate - b.dueDate)
      )
      setUrgentProspects(
        convertedProspects
          .filter((prospect) => prospect?.campaign?.disposition?.['Is Urgent'] === 'TRUE')
          .sort((a, b) => a.dueDate - b.dueDate)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedProspects])

  return (
    <>
      {urgentProspects.length > 0 && (
        <>
          <Box margin={3}>
            <Typography color="orange">
              <b>URGENT</b>
            </Typography>
            {isProspectsLoading ? (
              <Typography marginLeft={2} color={'grey'}>
                Loading...
              </Typography>
            ) : (
              <ul>
                {urgentProspects.map((prospect) => (
                  <li key={`${prospect['Prospect ID']}-${prospect.campaign?.id}`}>
                    {' '}
                    <Typography
                      component="span"
                      onClick={() => handleNavigate(prospect.campaign?.id, prospect['Prospect ID'])}
                      sx={{
                        display: 'inline',
                        cursor: 'pointer',
                        '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
                      }}
                    >
                      {formatDate(prospect?.dueDate) || 'Immediately'} - <b>{prospect.Name}</b> -{' '}
                      {prospect.campaign.name} - {prospect.campaign?.Disposition}
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </Box>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      <Box margin={3}>
        <Typography color="#57cf24">TODAY</Typography>
        {isProspectsLoading ? (
          <Typography marginLeft={2} color={'grey'}>
            Loading...
          </Typography>
        ) : todaysProspects.length > 0 ? (
          <ul>
            {todaysProspects.map((prospect) => (
              <li key={`${prospect['Prospect ID']}-${prospect.campaign?.id}`}>
                {' '}
                <Typography
                  component="span"
                  onClick={() => handleNavigate(prospect.campaign?.id, prospect['Prospect ID'])}
                  sx={{
                    display: 'inline',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
                  }}
                >
                  {formatDate(prospect?.dueDate)} - <b>{prospect.Name}</b> - {prospect.campaign.name} -{' '}
                  {prospect.campaign?.Disposition?.trim() ?? 'New'}
                </Typography>
              </li>
            ))}
          </ul>
        ) : (
          <Typography marginLeft={2} color={'grey'}>
            No tasks due today.
          </Typography>
        )}
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box margin={3}>
        <Typography color={'darkred'}>OVERDUE</Typography>
        {isProspectsLoading ? (
          <Typography marginLeft={2} color={'grey'}>
            Loading...
          </Typography>
        ) : overdueProspects.length > 0 ? (
          <ul>
            {overdueProspects.map((prospect) => (
              <li key={`${prospect['Prospect ID']}-${prospect.campaign?.id}`}>
                <Typography
                  component="span"
                  onClick={() => handleNavigate(prospect.campaign?.id, prospect['Prospect ID'])}
                  sx={{
                    display: 'inline',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
                  }}
                >
                  {formatDate(prospect?.dueDate)} - <b>{prospect.Name}</b> - {prospect.campaign.name} -{' '}
                  {prospect.campaign?.Disposition?.trim() ?? 'New'}
                </Typography>
              </li>
            ))}
          </ul>
        ) : (
          <Typography marginLeft={2} color={'grey'}>
            No overdue tasks.
          </Typography>
        )}
      </Box>
    </>
  )
}

export default Overdue
