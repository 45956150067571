const config = {
  app: {
    name: 'SALES',
    platform: 'sales',
    version: "1.2.5",
    hideAlertDelay: 10000,
  },
  environment: "qa",
  aws: {
    region: "us-east-1",
    cognito: {
      clientId: "ath3eeb277tcc72k6eure69u1",
      userPoolId: "us-east-1_RcInWGkdn",
    },
  },
  api: {
    baseUrl: "https://api.qa.leadcogrp.ca",
  },
  mui: {
    licenseKey: "ad331435b4fa4c9fece6894d5eb9d525Tz1sZWFkY28sRT0xOTI1MDA5OTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sS1Y9Mg==",
  },
  logRocket: {
    appId: "undefined",
  },
}

export default config
