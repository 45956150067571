import { Field } from 'formik'
import TextField from '@mui/material/TextField'

const TextArea = (props) => {
  const { name, label, width, ...rest } = props
  return (
    <Field name={name} label={label} {...rest}>
      {({ field, meta }) => {
        return (
          <TextField
            id={name}
            error={!!meta.touched && !!meta.error}
            helperText={meta.touched && meta.error ? meta.error : ''}
            multiline
            InputLabelProps={{
              style: { visibility: 'hidden' },
            }}
            size="small"
            sx={{ my: 1, width }}
            {...rest}
            {...field}
            placeholder={label}
          />
        )
      }}
    </Field>
  )
}

export default TextArea
