import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/formik-control'

import { useAddEventMutation } from '../../../../services/events/slice'
import { useGetCampaignDispositionsQuery } from '../../../../services/dispositions/slice'
import { useGetContactsQuery } from '../../../../services/contacts/slice'
import { useGetEventTypesQuery } from '../../../../services/eventTypes/slice'

const EventForm = ({ campaign, prospect, submitEvent, setSubmitEvent, cancelEvent, setShowDrawer, setIsSaving }) => {
  const campaignId = campaign?.id
  const prospectId = prospect?.['Prospect ID']

  const { data: dispositions } = useGetCampaignDispositionsQuery({ campaignId }, { skip: !campaignId })
  const { data: contacts } = useGetContactsQuery({ prospectId }, { skip: !prospectId })
  const { data: eventTypes } = useGetEventTypesQuery()

  const [dispositionOptions, setDispositionOptions] = useState([])
  const [contactOptions, setContactOptions] = useState([])
  const [eventTypeOptions, setEventTypeOptions] = useState([])
  const [addEvent, { isLoading: isSavingEvent }] = useAddEventMutation()

  useEffect(() => {
    setIsSaving(isSavingEvent)
  }, [setIsSaving, isSavingEvent])

  useEffect(() => {
    if (dispositions) {
      const currentDisposition = prospect?.campaign?.Disposition
      const restrictedDispositions = currentDisposition
        ? dispositions.find((disposition) => disposition?.Name === currentDisposition)?.cannotBecome
        : []
      const options = dispositions
        .filter((disposition) => disposition?.['Is Default'] !== 'TRUE')
        .filter((disposition) => !restrictedDispositions?.includes(disposition?.Name))
        .map((disposition) => ({
          key: disposition?.Name,
          value: disposition?.Name,
          isHidden: disposition?.['Is Hidden'] === true || disposition?.['Is Hidden'] === 'TRUE',
        }))
      setDispositionOptions(options)
    }
  }, [dispositions, prospect?.campaign?.Disposition])

  useEffect(() => {
    if (contacts) {
      const options = contacts.map((contact) => ({
        key: contact?.Name,
        value: contact?.['Contact ID'],
      }))

      options.unshift({ key: 'None', value: '' })

      setContactOptions(options)
    }
  }, [contacts])

  const initialValues = {
    Type: '',
    'Contact ID': '',
    Notes: '',
    Disposition: '',
    'Next Action Date': '',
  }

  useEffect(() => {
    if (eventTypes) {
      setEventTypeOptions(
        eventTypes
          ?.map((eventType) => ({
            key: eventType?.name,
            value: eventType.name,
            priority: eventType?.priority || 100,
          }))
          ?.sort((a, b) => (a?.priority || 0) - (b?.priority || 0)) || []
      )
    }
  }, [eventTypes])

  const validationSchema = Yup.object({
    Type: Yup.string().required('Required'),
    Disposition: Yup.string().required('Required'),
    'Next Action Date': Yup.date().when('Disposition', (Disposition, schema) => {
      const isHidden = dispositionOptions.find((option) => option.value === Disposition[0])?.isHidden
      return isHidden ? schema.notRequired() : schema.required('Required')
    }),
  })

  const onSubmit = async (values) => {
    const event = { ...values }
    event.Notes = event.Notes?.trim()
    await addEvent({ campaignId, prospectId, event })
    setSubmitEvent()
    setShowDrawer(false)
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (submitEvent) {
      formik.handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitEvent])

  useEffect(() => {
    if (cancelEvent) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelEvent])

  useEffect(() => {
    if (!campaignId || !prospectId) formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, prospectId])

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box display="flex" flexdirection="row" sx={{ flexGrow: 1, pt: 1 }}>
          <Box sx={{ mr: 2, ml: 1 }}>
            <Box>
              <FormikControl control="select" label="Type" name="Type" width={200} options={eventTypeOptions} />
            </Box>
            <Box>
              <FormikControl control="select" label="Contact" name="Contact ID" width={200} options={contactOptions} />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <FormikControl control="textarea" label="Notes" name="Notes" margin="normal" rows={4} fullWidth />
          </Box>
          <Box sx={{ ml: 2, mr: 1 }}>
            <Box>
              <FormikControl
                control="select"
                label="Disposition"
                name="Disposition"
                width={230}
                options={dispositionOptions}
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <FormikControl control="datetime" label="Next Action Date" name="Next Action Date" />
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  )
}

export default EventForm
