import Box from '@mui/material/Box'
import Paper from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import EventPin from './eventPin'
import { convertToDate, formatDate } from '../../../../utils/dates'

import { useGetUsersQuery } from '../../../../services/users/slice'
import { useGetContactsQuery } from '../../../../services/contacts/slice'
import { usePinEventMutation } from '../../../../services/events/slice'

const Event = ({ event }) => {
  const { data: users } = useGetUsersQuery()
  const { data: contacts } = useGetContactsQuery({ prospectId: event['Prospect ID'] })
  const [pinEvent] = usePinEventMutation()

  const localTime = (variable) => {
    try {
      let date = variable
      if (typeof date === 'string') {
        date = convertToDate(variable)
      }
      if (date instanceof Date) {
        return date.toLocaleTimeString()
      }
    } catch {
      return variable
    }
  }

  const getUsername = (email) => {
    const user = users?.find((user) => user.email === email)
    return user ? `${user.givenName} ${user.familyName}` : undefined
  }

  const getContactName = (contactId) => {
    const contact = contacts?.find((contact) => contact['Contact ID'] === contactId)
    return contact?.Name ?? undefined
  }

  const onPin = (state) => {
    pinEvent({ eventId: event['Event ID'], pinState: state })
  }

  return (
    <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
      <Box alignContent="start" sx={{ width: 200, flexShrink: 0, p: 1 }}>
        <Box display="flex" alignItems="center">
          <Typography fontWeight={600} align="left">
            {event['Type']}
          </Typography>
          <EventPin isPinned={event['Is Pinned']} onPin={onPin} />
        </Box>
        <Typography align="left">{localTime(event['Created Date'])}</Typography>
        {getUsername(event['User ID']) !== undefined ? (
          <Typography align="left">By {getUsername(event['User ID'])}</Typography>
        ) : null}
        {event?.['Contact ID'] && getContactName(event['Contact ID']) && (
          <Typography align="left">With {getContactName(event['Contact ID'])}</Typography>
        )}
      </Box>
      <Divider orientation="vertical" flexItem sx={{ p: 1, m: 1 }} />
      <Box alignContent="start" sx={{ flexGrow: 1, overflow: 'auto', p: 1 }}>
        <Typography color="grey" align="left">
          Notes
        </Typography>
        <Typography align="left" whiteSpace="pre-line">
          {event.Notes ?? 'No note for this event'}
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem sx={{ p: 1, m: 1 }} />
      <Box alignContent="start" sx={{ width: 200, flexShrink: 0, p: 1 }}>
        <Typography color="grey">Disposition:</Typography>
        {event?.Disposition ? (
          <Typography fontWeight={600}>{event.Disposition}</Typography>
        ) : (
          <Typography color="grey">None</Typography>
        )}
        <Typography color="grey">Next Action Date:</Typography>
        {event['Next Action Date'] ? (
          <Typography fontWeight={600}>{formatDate(convertToDate(event['Next Action Date']))}</Typography>
        ) : (
          <Typography color="grey">None</Typography>
        )}
      </Box>
    </Paper>
  )
}

export default Event
